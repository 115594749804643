[data-animation="default"] {
    opacity: 0;
}

[data-animation="fade-in"] {
    opacity: 0;
    transform: translateY(100px);
}

[data-animation="zoom-in"] {
    scale: 1.15;
}