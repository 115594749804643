.teaser-grid {
  $this: &;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__item {
    position: relative;
    grid-column: span 1;
    min-height: 500px;

    @include media-breakpoint-down(md) {
      min-height: 320px;
    }

    &--big {
      grid-column: span 2;

      @include media-breakpoint-down(md) {
        grid-column: span 1;
      }
    }

    &:hover {
      #{$this}__image {
        &::after {
          background-color: rgba(black, 0.75);
        }
      }
    }

    @media (hover: none) {
      #{$this}__image {
        &::after {
          background-color: rgba(black, 0.75);
        }
      }
    }
  }

  &__image {
    position: absolute;
    inset: 0;
    z-index: 5;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(black, 0.5);
      z-index: 5;
      @include effect(all, 0.5s, ease-in-out);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2rem calc(50px + 3rem) 2rem 2rem;
    height: 100%;
    z-index: 10;
  }

  &__btn {
    position: absolute;
    inset: auto 2rem 2rem auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: var(--btn-primary);
    border-radius: 50vw;
    z-index: 15;
    @include effect();

    &::after {
      content: "";
      position: relative;
      width: 20px;
      height: 20px;
      background-color: white;
      mask-image: url("../img/icon/icon-arrow-right.svg");
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 20px;
    }

    &:hover {
      background-color: var(--btn-primary-hover);
    }
  }

  &__title {
    font-size: 32px;
    margin-block-end: 0.5rem;
    --title: white;
  }

  &__text {
    --text: white;
    font-size: 16px;
  }
}

.rowteaser {
  display: flex;
  flex-flow: row wrap;
  margin-block: calc(var(--bs-gutter-x) * 0.5);

  .content {
    background-color: transparent;
    padding: 30px;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: flex-start;
    &[data-bg="white"] {
      background-color: #ffffff;
    }

    .row {
      width: 100%;
    }
    .title-secondary {
      font-size: 26px;
    }
    h3 {
      font-size: 24px;
      margin-block-start: 0rem;
    }
    p {
      font-size: 18px;
    }
    .btn-bar {
      .btn {
        font-size: 16px;
        padding-block: 0.5rem;
        padding-inline: 1rem;
      }
    }
  }
  .title-and-icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-block: 0 2rem;
    &__icon {
      width: 60px;
      max-height: 60px;
      margin-right: 1rem;
    }
    .title-secondary {
      margin-bottom: 0;
    }
  }
  &.highlighted-rowteaser {
    .content {
      background-color: var(--color-secondary);

      * {
        color: #ffffff;
      }
      .title-and-icon {
        &__icon {
          background-color: #ffffff;
          border-radius: 150px;
        }
      }
      .btn-bar {
        a.btn {
          background-color: var(--color-tertiary);

          &:hover {
            background-color: #ffffff;
            color: var(--color-secondary);
          }
        }
      }
    }
  }
}
