.table-default {
  overflow-x: auto;

  table {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    border-collapse: collapse;
  }

  tr {
    display: block;
    width: 100%;
    @include effect();
  }

  td,
  th {
    width: 100%;
    text-align: left;
    min-width: 200px;
    padding: 1rem;

    &:first-child {
      padding-inline-start: 0.5rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 0.5rem;
    }
    &.digit {
      text-align: right;
    }
  }

  thead {
    tr {
      border-bottom: 2px solid var(--text);
    }
    th {
      vertical-align: bottom;

      span {
        font-size: 20px;
        color: var(--color-primary);
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--border);

      &:hover {
        background-color: var(--bg-light);
      }
    }
    td {
    }
  }

  &__highlight {
    background-color: white;

    &--dark {
      background-color: var(--color-tertiary);
    }
    &--light {
      background-color: var(--bg-light);
    }
  }

  &__title {
    font-size: 24px;
  }

  &__meta {
    min-width: 100px !important;
    text-align: right !important;
  }

  &.table-width-evenly-3 {
    tr {
      display: flex;
    }
    td,
    th {
      width: calc(100% / 3);
      display: inline-block;
    }
  }
}
