.footer {
    padding-block: 4rem;
	text-align: center;
    background-color: var(--color-primary-4);

	&__list {
        margin: 0;
        padding: 0;
        list-style: none;
		display: flex;
		justify-content: center;
        margin-block-end: .5rem;
        
        @include media-breakpoint-down(md) {
            margin-block-end: .25rem;
        }
	}

    &__item {
        position: relative;
        margin-inline-end: 0.5rem;
        font-weight: 400;

        &:not(:last-child) {
            &::after {
                content: "/";
                position: relative;
                display: inline-block;
                margin-inline-start: 0.5rem;
            }
        }
    }

    &__link {
        @include fluid-type($min_width, $max_width, 16px, 20px);
        color: var(--text);
        text-decoration: none;

        &:hover {
            color: var(--meta);
        }
    }

    &__copy {
        color: var(--meta);
        font-size: 16px;
        
        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }
}
