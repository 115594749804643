.side-teaser {
    pointer-events: none;
    $this: &;
    position: fixed;
    inset: auto auto 40px 40px;
    display: block;
    padding: 1rem;
    width: 100%;
    max-width: 280px;
    font-size: 16px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 40px rgba(black, .2);
    z-index: 900;
    opacity: 0;
    
    @include media-breakpoint-down(md) {
        inset: auto auto 20px 20px;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        inset: -10px -10px auto auto;
        width: 30px;
        height: 30px;
        background-color: var(--btn-secondary);
        border-radius: 50vw;
        border: 0;
        
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            background-color: white;
            mask-image: url('../img/icon/icon-close.svg');
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: 10px;
        }

        &:hover {
            background-color: var(--btn-secondary-hover);
        }
    }

    &__link {
        display: flex;
        align-items: center;
        color: var(--color-primary);
        text-decoration: none;

        &:hover {
            #{$this}__img {
                scale: 1.05;
            }
        }
    }

    &__title {
        font-weight: 600;
        line-height: 1.2;
    }

    &__img {
        flex-shrink: 0;
        margin-right: 1rem;
        width: 100%;
        height: auto;
        @include effect();
    }
}