.animated-svg-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 10rem;

    @include media-breakpoint-down(md) {
        gap: 8rem;
    }

    @include media-breakpoint-down(sm) {
        gap: 4rem;
    }
}

.animated-svg-item {
    display: flex;
    gap: 4rem;

    .content {
        align-self: flex-end;
    }

    &:nth-child(2n) {
        flex-direction: row-reverse;

        .content {
            text-align: right;
        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        gap: 2rem;

        &:nth-child(2n) {
            flex-direction: column;

            .content {
                text-align: initial;
            }
        }
    }

}

.animated-svg {
    display: flex;
    align-items: flex-end;
    // aspect-ratio: 1/1;
    width: 100%;
    max-width: 320px;

    @include media-breakpoint-down(sm) {
        max-width: 200px;
    }

    &--overflow {
        overflow-x: hidden;
    }

    svg {
        width: 100%;
        height: auto;
        overflow: visible;
    }
}