.hero {
  position: relative;
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 20px;
  background-color: transparent;

  &--with-overlay {
    width: 100vw;
    &:before {
      content: "";
      display: block;
      background-image: url(../img/content/titelbild.jpg);
      background-position: bottom center;
      background-size: cover;
      height: 100vh;
      width: 100vw;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      z-index: -10;
    }
    .hero-image {
      bottom: 0;
      right: 0;

      img {
        object-position: bottom;
        object-fit: contain;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: bottom right;
        @include media-breakpoint-down(lg) {
          object-fit: contain;
          position: absolute;
          left: -75vw !important;
          width: 175vw;
        }
      }
    }
  }
}

.hero-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

// .hero-title {
//   position: relative;
//   @include fluid-type($min_width, $max_width, 40px, 90px);
//   color: white;
//   z-index: 10;
//   font-weight: 600;

//   &[data-shadow="text"] {
//     text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
//   }
// }

.hero-title {
  position: relative;
  @include fluid-type($min_width, $max_width, 40px, 86px);
  color: white;
  z-index: 10;
  font-weight: 600;
  line-height: 0.9em;
  text-align: center;
  pointer-events: none;

  &[data-shadow="text"] {
    text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  }
  span {
    &.smaller {
      @include fluid-type($min_width, $max_width, 26px, 38px);
    }
  }
  @include media-breakpoint-down(sm) {
    line-height: 1em;
  }
}

.hero-detail {
  position: relative;
  background-color: var(--bg-dark);
  width: 100%;
  height: 100%;
  max-height: 800px;
  aspect-ratio: 3/2;

  &[data-size="full"] {
    height: 100vh;
    max-height: initial;
    aspect-ratio: initial;

    @include media-breakpoint-down(lg) {
      height: initial;
      aspect-ratio: 5/3;
    }
  }

  .image {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      &[object-position="top"] {
        object-position: top;
      }

      &[object-position="bottom"] {
        object-position: bottom;
      }
    }
  }
}
