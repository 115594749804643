.timeline-logo {
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  display: block;
  padding-block-end: 30px;
}
.timeline-logo-animation {
  max-width: 400px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;

  &::after {
    content: "";
    position: absolute;
    width: 2px;
    background: var(--color-quaternary);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
    @media (max-width: 768px) {
      left: 90px;
    }
  }

  &__container {
    padding: 15px 30px 15px 0;
    position: relative;
    background: inherit;
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
      padding-left: 100px;
      padding-right: 0px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: calc(50% - 8px);
      right: -8px;
      background: #ffffff;
      border: 2px solid var(--color-quaternary);
      border-radius: 16px;
      z-index: 1;
    }
    &::before {
      content: "";
      position: absolute;
      width: 50px;
      height: 2px;
      top: calc(50% - 1px);
      right: 8px;
      background: var(--color-quaternary);
      z-index: 1;
    }
    .date {
      position: absolute;
      display: inline-block;
      top: calc(50% - 10px);
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: var(--color-primary);
      text-transform: uppercase;
      letter-spacing: 1px;
      z-index: 1;
      @media (max-width: 768px) {
        letter-spacing: normal;
        text-transform: none;
        left: 0;
        right: 0;
        width: 70px;
        text-align: left;
      }
    }

    .icon {
      position: absolute;
      display: inline-block;
      width: 40px;
      height: 40px;
      padding: 9px 0;
      top: calc(50% - 20px);
      background: #ffffff;
      border: 2px solid var(--color-quaternary);
      border-radius: 40px;
      text-align: center;
      font-size: 18px;
      color: var(--color-quaternary);
      z-index: 1;
    }

    .timeline--content {
      padding: 30px 90px 30px 30px;
      background: var(--color-tertiary-4);
      position: relative;
      border-radius: 15px;
      h2 {
        margin: 0 0 10px 0;
        font-size: 18px;
        font-weight: normal;
        color: var(--color-quaternary);
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
      }
    }

    &.left {
      left: 0;
      @media (max-width: 768px) {
      }
      &::after {
        @media (max-width: 768px) {
          left: 82px;
        }
      }
      &::before {
        @media (max-width: 768px) {
          left: 100px;
          border-color: transparent var(--color-quaternary) transparent
            transparent;
        }
      }
      .date {
        right: -100%;
        left: 100%;
        @media (min-width: 769px) {
          padding-left: 30px;
          text-align: left;
        }

        @media (max-width: 768px) {
          right: auto;
          left: 0;
        }
      }
      .icon {
        right: 56px;
        @media (max-width: 768px) {
          right: auto;
          left: 110px;
        }
      }
      .timeline--content {
        @media (max-width: 768px) {
          padding: 30px 30px 30px 65px;
        }
      }
    }
    &.right {
      left: 50%;
      padding: 15px 0px 15px 30px;

      @media (max-width: 768px) {
        left: 0;
        padding-left: 100px;
      }
      &::after {
        left: -8px;
        @media (max-width: 768px) {
          left: 82px;
        }
      }
      &::before {
        left: 8px;
        @media (max-width: 768px) {
          left: 100px;
          border-color: transparent var(--color-quaternary) transparent
            transparent;
        }
      }
      .date {
        right: 100%;
        left: -100%;
        @media (min-width: 769px) {
          padding-right: 30px;
          text-align: right;
        }
        @media (max-width: 768px) {
          right: auto;
          left: 0;
        }
      }
      .icon {
        left: 56px;
        @media (max-width: 768px) {
          right: auto;
          left: 110px;
        }
      }
      .timeline--content {
        padding: 30px 30px 30px 90px;
        @media (max-width: 768px) {
          padding: 30px 30px 30px 65px;
        }
      }
    }
  }
}
